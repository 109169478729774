@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

body {
    color: #808080;
    background: #ECF2FA;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 1.5;
}

p {
    margin: 0;
}

.btn, .btn:hover, .btn:active, .btn:focus {
    box-shadow: none;
    overflow: none;
}

.form-control,
.form-control:hover,
.form-control:active,
.form-control:focus {
    height: 44px;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}

.display-block {
    display: block !important;
    width: 100%;
}

.font-title {
    font-size: 34px;
}

.font-medium {
    font-size: 24px;
}

.font-normal {
    font-size: 18px;
}

.font-to-normal {
    font-size: 16px;
}

.font-sm-normal {
    font-size: 16px;
}

.font-small {
    font-size: 12px;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.cursor {
    cursor: pointer;
}

.order .product .product-box {
    border-radius: 8px;
}

.order .product .product-box img {
    width: 120px;
    height: 120px;
    display: block;
    object-fit: cover;
    margin:  0 auto;
}

.order .cart .btn-remove-all {
    background: #FFECEC;
    border: 1px solid #FFECEC;
    color: #F1494B;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.order .cart .total-label {
    font-weight: 500;
    font-size: 21px;
}

.order .cart .total-price {
    font-weight: 500;
    font-size: 32px;
}

.order .cart .checkout {
    height: 50px;
    border-radius: 12px;
    background: #63A0F0;
    border: 1px solid #63A0F0;
    text-transform: uppercase;
    font-weight: 500;
}

.order .cart .product .product-box img {
    width: 50px;
    height: 50px;
    margin: 0 0;
    float: left;
}

.order .cart .product .product-box p {
    display: inline-block;
}

.order .cart .product .product-box .buttons {
    float: right;
    position: relative;
    top: 10px;
}

.order .cart .product .product-box .buttons .count {
    width: 40px;
    height: 40px;
}

.order .cart .product .product-box .buttons .btn {
    background: #EAF2FD;
    border: 1px solid #EAF2FD;
    color: #3D87EE;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
  
.auth-inner {
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border-radius: 15px;
    transition: all .3s;
}

.auth-wrapper img {
    display: block;
    margin: 0 auto;
}

@media screen and (max-w-dth: 780px) {
    .navbar .navbar-brand {
        width: 100% !important;
    }

    .navbar .navbar-toggler {
        position: absolute;
        right: 0;
    }
}

textarea.form-control,
textarea.form-control:hover,
textarea.form-control:active,
textarea.form-control:focus {
    height: 150px;
    resize: none;
}

.payment-status-btn {
    width: 35px;
    height: 35px;
    padding: 0 !important;
    border-radius: 50%;
}

.product-search .input-group-prepend .input-group-text {
    background: #ffffff !important;
    border: 1px solid #ffffff !important;
}

.product-search .form-control {
    background: #ffffff !important;
    border: 1px solid #ffffff !important;
    height: 50px !important;
}

.hero {
    min-height: 100vh;
    margin-top: -77px;
}

.hero .content,
.hero .happy-person {
    min-height: 100vh;
}

.hero .content {
    background: #6586B0;
}

.hero .happy-person {
    background: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.4)), url('../images/banner/gocashless.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.hero .content {
    border-bottom-right-radius: 40px;
    padding: 120px 20px;
}

.hero .content {
    padding: 150px 50px;
}

.hero .happy-person {
    border-bottom-left-radius: 40px;
}

.hero .content .title {
    font-size: 70px;
    font-weight: 700;
    line-height: 85px;
}

.hero .content .description {
    font-size: 24px;
    font-weight: 500;
}

.hero .content .paragraph {
    font-size: 18px;
    font-weight: 300;
}

.hero .content .btn {
    width: 220px;
    height: 55px;
    line-height: 40px;
    border-radius: 28px;
}

.header-home {
    background: transparent !important;
    height: 77px;
}

.header-home .get-started-btn {
    padding: 10px 30px;
    border-radius: 28px;
}

.header-home.scrool {
    background: #ffffff !important;
}

.header-home .nav-item .nav-link {
    font-size: 16px !important;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
    color: #ffffff !important;
}

.header-home.scrool .nav-item .nav-link {
    color: #000 !important;
}

@media screen and (min-width: 780px) {
    .header-home .logo {
        padding-left: 30px;
    }
}

@media screen and (max-width: 780px) {
    .header-home {
        background: #ffffff !important;
    }

    .header-home .logo {
        position: relative;
        top: -5px;
    }

    .header-home .navbar-collapse {
        background: #ffffff;
        position: absolute;
        top: 77px;
        right: 0;
        left: 0;
        min-height: 100%;
        border-top: 1px solid #f9f9f9;
        padding: 20px;
    }

    .header-home .nav-item .nav-link {
        display: block;
        text-align: center;
        color: #000 !important;
    }

    .header-home .get-started-btn {
        display: block;
        width: 70%;
        margin: 0 auto;
        text-align: center;
        margin-top: 15px;
    }

    .hero .content .title {
        font-size: 60px;
        line-height: 55px;
    }

    .hero .content {
        border-bottom-right-radius: 0;
        padding: 120px 20px;
        background: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.4)), url('../images/banner/gocashless.jpg') no-repeat !important;
        background-size: cover !important;
        background-position: center;
        min-height: calc(100vh - 77px) !important;
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.mobile-view {
    display: none;
}

.web-view {
    display: block;
}

@media screen and (max-width: 780px) {
    .mobile-view {
        display: block;
    }
    
    .web-view {
        display: none;
    }
}

.border-radius {
    border-radius: 4px;
}